import React, { Component } from "react";
import Banner from "./Banner.js";

class OtherActivities extends Component {
  render() {
    return (
      <div className="OtherActivities">
        <Banner bannerID="activities" title="Other Activities" />
        <div className="content">
          <div className="table-container">
            <table className="table table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th title="Field #1">Company/Location</th>
                  <th title="Field #2">Activity Type</th>
                  <th title="Field #3">Trip Advisor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d4995285-Reviews-Napa_Valley_Horse_Company-Napa_Napa_Valley_California.html"
                    >
                      Napa Valley Horse Company
                    </a>
                  </td>
                  <td>Horseback Riding</td>
                  <td>~4.5 - 23 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d8093773-Reviews-Napa_River_History_Tour-Napa_Napa_Valley_California.html"
                    >
                      Historic Kayak Tour of Napa Valley
                    </a>
                  </td>
                  <td>River Tour</td>
                  <td>~5 stars - 12 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/AttractionProductReview-g32766-d11453073-Napa_Valley_Hot_Air_Balloon_Ride_with_Sparkling_Wine_Brunch-Napa_Napa_Valley_Califo.html"
                    >
                      Napa Valley Hot-Air-Balloon Ride with Wine Brunch
                    </a>
                  </td>
                  <td>Balloon Ride &amp; Wine</td>
                  <td>5 stars - 352 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d116257-Reviews-Balloons_Above_the_Valley-Napa_Napa_Valley_California.html"
                    >
                      Balloons Above the Valley
                    </a>
                  </td>
                  <td>Hot Air Ballon Ride</td>
                  <td>~4.5 - 703 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/AttractionProductDetail-g32766-d14782218-Cycling_in_the_Vineyards_Off_Road_Bike_Tour-Napa_Napa_Valley_California.html"
                    >
                      Cycling in the Vineyards Off-Road Bike Tour
                    </a>
                  </td>
                  <td>Bike Tour</td>
                  <td>~5 star - 1 review</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d11773212-Reviews-The_Blue_Note_Napa-Napa_Napa_Valley_California.html"
                    >
                      Blue Note Napa
                    </a>
                  </td>
                  <td>Live Music</td>
                  <td>4.6 stars - 271 Google reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d282679-Reviews-Skyline_Wilderness_Park-Napa_Napa_Valley_California.html"
                    >
                      Skyline Wilderness Park
                    </a>
                  </td>
                  <td>Hiking</td>
                  <td>~4.5 - 135 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d2415286-Reviews-Off_the_Hook_Fly_Fishing-Napa_Napa_Valley_California.html"
                    >
                      Off the Hook Fly Fishing
                    </a>
                  </td>
                  <td>Fly Fishing</td>
                  <td>~5 star - 42 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.golfadvisor.com/courses/40-rancho-solano-golf-coursehttps://www.tripadvisor.com/Attraction_Review-g32367-d7738594-Reviews-Rancho_Solano_Golf_Course-Fairfield_California.html"
                    >
                      Rancho Solano Golf Course
                    </a>
                  </td>
                  <td>Golfing</td>
                  <td>~4.5 - 9 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d13796296-Reviews-CaliSpeed_MotorSports-Napa_Napa_Valley_California.html"
                    >
                      CaliSpeed MotorSports
                    </a>
                  </td>
                  <td>Car Racing</td>
                  <td>~5 star - 24 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d1114392-Reviews-Quent_Cordair_Fine_Art-Napa_Napa_Valley_California.html"
                    >
                      Quent Cordair Fine Art
                    </a>
                  </td>
                  <td>Art Gallery</td>
                  <td>~5 star - 52 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32143-d2248788-Reviews-Spa_Solage-Calistoga_Napa_Valley_California.html"
                    >
                      Spa Solage                   
                    </a>
                  </td>
                  <td>Spa</td>
                  <td>~4.5 star - 133 reviews</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tripadvisor.com/Attraction_Review-g32766-d4520738-Reviews-The_Spa_at_Napa_River_Inn-Napa_Napa_Valley_California.html"
                    >
                      Spa at the Napa River Inn
                    </a>
                  </td>
                  <td>Spa</td>
                  <td>~5 star - 33 reviews</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default OtherActivities;
