import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Banner.scss";

class Banner extends Component {
  render() {
    return (
      <div className={"Banner " + this.props.bannerID}>
        <h1>
          <NavLink to="/guide">
            <i className="arrow left" />
            Travel Guide
          </NavLink>
        </h1>
        <h2>{this.props.title}</h2>
        <div className="overlay-shadow top" />
        <div className="overlay-shadow bottom" />
      </div>
    );
  }
}

export default Banner;
