import React, { Component } from "react";
import Measure from "./Measure.js";
import Banner from "./Banner.js";
import airbnb from "./images/airbnb.jpg";
import vrbo from "./images/vrbo.jpg";

class Lodging extends Component {
  constructor(props) {
    super(props);

    this.hotels = [
      {
        name: "Auberge du Soleil",
        url: "https://aubergeresorts.com/aubergedusoleil/",
        price: 1350,
        distance: 3.8,
        class: 5
      },
      {
        name: "DoubleTree by Hilton Hotel Sonoma Wine Country",
        url:
          "https://doubletree3.hilton.com/en/hotels/california/doubletree-by-hilton-hotel-sonoma-wine-country-RLSC-DT/about/amenities.html",
        price: 205,
        distance: 35.3,
        class: 4
      },
      {
        name: "Harvest Inn St. Helena",
        url: "https://www.harvestinn.com/",
        price: 819,
        distance: 2.2,
        class: 4
      },
      {
        name: "Best Western Plus Stevenson Manor",
        url:
          "https://www.bestwestern.com/en_US/book/hotels-in-calistoga/best-western-plus-stevenson-manor/propertyCode.05571.html",
        price: 399,
        distance: 7.3,
        class: 3
      },
      {
        name: "UpValley Inn & Hot Springs",
        url: "https://upvalleyinn.com/",
        price: 369,
        distance: 7.4,
        class: 3
      },
      {
        name: "Mount View Hotel & Spa",
        url: "https://mountviewhotel.com/",
        price: 342,
        distance: 7.2,
        class: 3
      },
      {
        name: "The Bergson",
        url: "https://www.thebergson.com/",
        price: 334,
        distance: 7.1,
        class: 3
      },
      {
        name: "EuroSpa & Inn",
        url: "https://www.eurospa.com/",
        price: 328,
        distance: 7,
        class: 3
      },
      {
        name: "Roman Spa Hot Springs Resort",
        url: "https://www.romanspahotsprings.com/",
        price: 315,
        distance: 7.3,
        class: 3
      },
      {
        name: "Hilton Garden Inn Sonoma County Airport",
        url:
          "https://hiltongardeninn3.hilton.com/en/hotels/california/hilton-garden-inn-sonoma-county-airport-STSAPGI/accommodations/index.html",
        price: 196,
        distance: 27,
        class: 3
      },
      {
        name: "Blackbird Inn",
        url: "https://www.blackbirdinnnapa.com/",
        price: 229,
        distance: 20.2,
        class: 3
      },
      {
        name: "Hawthorn Suites by Wyndham Napa Valley",
        url: "https://www.napavalleyinns.com/",
        price: 217,
        distance: 21.1,
        class: 3
      },
      {
        name: "Hampton Inn & Suites Rohnert Park - Sonoma County",
        url:
          "https://hamptoninn3.hilton.com/en/hotels/california/hampton-inn-and-suites-rohnert-park-sonoma-county-STSRPHX/index.html?SEO_id=GMB-HP-STSRPHX",
        price: 215,
        distance: 36.4,
        class: 3
      },
      {
        name: "Backyard Garden Oasis B&B",
        url: "https://www.backyardgardenoasis.com/",
        price: 159,
        distance: 23.1,
        class: 3
      },
      {
        name: "Hotel La Rose",
        url: "https://www.hotellarose.com/en-us",
        price: 209,
        distance: 29.6,
        class: 3
      },
      {
        name: "El Bonita Motel",
        url: "https://elbonita.com/",
        price: 280,
        distance: 2,
        class: 2
      },
      {
        name: "Wine Valley Lodge",
        url: "https://www.winevalleylodge.com/",
        price: 176,
        distance: 21.6,
        class: 2
      }
    ];

    this.maxValues = {
      price: 0,
      distance: 0
    };

    for (const hotel of this.hotels) {
      if (hotel.price > this.maxValues.price) {
        this.maxValues.price = hotel.price;
      }
      if (hotel.distance > this.maxValues.distance) {
        this.maxValues.distance = hotel.distance;
      }
    }

    function compareHotels(a, b) {
      if (a.class !== b.class) {
        return b.class - a.class;
      }
      if (a.price !== b.price) {
        return a.price - b.price;
      }
      if (a.distance !== b.distance) {
        return a.distance - b.distance;
      }
      return 0;
    }

    this.hotels.sort(compareHotels);
  }

  getStars(hotelClass) {
    var stars = "";
    for (let i = 0; i < hotelClass; i++) {
      if (!!stars) {
        stars += " ";
      }
      stars += "★";
    }
    return stars;
  }

  render() {
    const hotels = this.hotels.map(hotel => (
      <tr key={hotel.name}>
        <td>
          <a target="_blank" rel="noopener noreferrer" href={hotel.url}>
            {hotel.name}
          </a>
        </td>
        <td>
          <Measure value={hotel.price} prefix="$" max={this.maxValues.price} />
        </td>
        <td>
          <Measure
            value={hotel.distance}
            suffix=" miles"
            max={this.maxValues.distance}
          />
        </td>
        <td>{this.getStars(hotel.class)}</td>
      </tr>
    ));
    return (
      <div className="Lodging">
        <Banner bannerID="lodging" title="Lodging" />
        <div className="content">
          <h2>Hotels</h2>
          <p>
            We strongly encourage guests who would like to attend to book their
            hotels as soon as possible. Our wedding will take place during
            Harvest season (the most popular time of the year) and this means
            the inventory will go quickly.
          </p>
          <p>
            All of the following currently have available rooms for the dates &
            have four or above stars from Google users:
          </p>
          <div className="table-container">
            <table className="table table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th title="Field #1">Hotel</th>
                  <th title="Field #2">Price per Night</th>
                  <th title="Field #3">Distance from Venue</th>
                  <th title="Field #4">Hotel Class</th>
                </tr>
              </thead>
              <tbody>{hotels}</tbody>
            </table>
          </div>
          <h2>Alternative Lodging</h2>
          <p>
            The dates are marked for the 17th - 20th. Please change according to
            your needs. Also, please be cautious of scams and make sure to check
            in with your host and read reviews! Thank you!
          </p>
          <div className="img-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airbnb.com/s/Saint-Helena--CA--United-States/homes?refinement_paths[]=/homes&adults=1&children=0&checkin=2019-10-17&checkout=2019-10-20&allow_override[]=&search_type=UNKNOWN&s_tag=P_P49UHE"
            >
              <img src={airbnb} alt="AirBnB" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.vrbo.com/search/keywords:saint-helena-ca-usa/arrival:2019-10-17/departure:2019-10-20?petIncluded=false&ssr=true"
            >
              <img src={vrbo} alt="VRBO" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Lodging;
