import React, { Component } from "react";
import "./PhotoWall.scss";
var images = require.context("./photos", true);

class PhotoWall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spotlight: "",
      showSpotlight: false
    };

    this.closeSpotlight = this.closeSpotlight.bind(this);
    this.selectPhoto = this.selectPhoto.bind(this);
  }

  getPhotoFilePath(photo, size) {
    return images("./" + size + "/" + photo.name + ".jpg");
  }

  renderPhotos() {
    return this.props.photos.map(photo => {
      return (
        <div
          style={{
            backgroundImage: `url(${this.getPhotoFilePath(photo, "sm")})`
          }}
          className="photo"
          key={photo.name}
          onClick={this.selectPhoto(photo.name)}
        >
          <img alt={photo.title} src={this.getPhotoFilePath(photo, "sm")} />
        </div>
      );
    });
  }

  getPhoto(name) {
    for (let i = 0; i < this.props.photos.length; i++) {
      let photo = this.props.photos[i];
      if (photo.name === name) {
        return photo;
      }
    }
    throw new Error("unrecognized photo name: ", name);
  }

  closeSpotlight() {
    this.setState({
      spotlight: "",
      showSpotlight: false
    });
  }

  selectPhoto(name) {
    return function() {
      this.setState({
        spotlight: name,
        showSpotlight: true
      });
    }.bind(this);
  }

  renderSpotlight() {
    if (this.state.showSpotlight) {
      let photo = this.getPhoto(this.state.spotlight);
      return (
        <div className="glass">
          <div className="photo">
            <div className="spotlight-header">
              <div className="photo-info">
                <p className="photo-title">{photo.title}</p>
                <p className="where-when">
                  {photo.location} &mdash; {photo.year}
                </p>
              </div>
              <div onClick={this.closeSpotlight} className="close-icon" />
            </div>
            <img alt={photo.title} src={this.getPhotoFilePath(photo, "lg")} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="PhotoWall">
        <div className="wall">{this.renderPhotos()}</div>
        <div className="spotlight">{this.renderSpotlight()}</div>
      </div>
    );
  }
}

export default PhotoWall;
