import React, { Component } from "react";
import "./DynamicInput.scss";

class DynamicInput extends Component {
  constructor(props) {
    super(props);
    let initialValue = this.props.value;
    if (!initialValue) {
      initialValue = "";
    }
    this.state = {
      value: initialValue,
      isLoading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      isLoading: true
    });
    this.props.onSave(this.state.value);
  }

  renderSubmitButton() {
    if (this.state.isLoading) {
      return <div className="loading-indicator right-button">Saving...</div>;
    }
    if (
      (!this.state.value && !this.props.value) ||
      this.state.value === this.props.value
    ) {
      return null;
    }
    return (
      <input
        className="right-button dynamic-submit"
        type="submit"
        value="Save"
      />
    );
  }

  disabled() {
    return !!this.props.disabled;
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="DynamicInput">
        <input
          type="text"
          className="dynamic-input"
          value={this.state.value}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          disabled={this.disabled()}
        />
        {this.renderSubmitButton()}
      </form>
    );
  }
}

export default DynamicInput;
