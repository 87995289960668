import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import names from "./images/ashly-ryan.png";
import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="invite-banner">
          <p className="invite-text">
            You are cordially invited to the wedding celebration of
            <br />
            <img alt="Ashly + Ryan" src={names} />
          </p>
          <p className="date">Friday the 18th of October, at 4:30pm</p>
          <div className="overlay-shadow top" />
          <div className="overlay-shadow bottom" />
        </div>
        <div className="sections">
          <NavLink to="/story" className="section story">
            <h3>Our story</h3>
            <p className="subtext">How we met, and our adventures since.</p>
          </NavLink>
          <NavLink to="/details" className="section details">
            <h3>Know before you go</h3>
            <p className="subtext">
              Where to go, when to arrive, what to wear, and more!
            </p>
          </NavLink>
          <NavLink to="/guide" className="section guide">
            <h3>Bon voyage!</h3>
            <p className="subtext">
              Our travel recommendations on making your Napa visit an adventure
              to remember. Stay for the night or the weekend; up to you!
            </p>
          </NavLink>
          <NavLink to="/rsvp" className="section rsvp">
            <h3>RSVP</h3>
            <p className="subtext">Please let us know whether you can come!</p>
          </NavLink>
          <a
            href="http://www.zola.com/registry/ashlyandryan"
            target="_blank"
            rel="noopener noreferrer"
            className="section registry"
          >
            <h3>Registry</h3>
            <p className="subtext">
              Your presence is the greatest gift of all. However, if you wish to
              honor us with a gift, we have registered a list with Zola.
            </p>
          </a>
        </div>
      </div>
    );
  }
}

export default Home;
