import React, { Component } from "react";
import localStorage from "local-storage";
import API from "../API";
import DynamicInput from "./DynamicInput";
import "./GuestCard.scss";

class GuestCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guest: this.props.guest,
      error: "",
      loading: ""
    };

    if (this.props.locked && !this.props.guest.response) {
      this.state.guest.response = "decline";
    }

    this.setResponse = this.setResponse.bind(this);
    this.setFoodPreference = this.setFoodPreference.bind(this);
    this.setDietaryRestrictions = this.setDietaryRestrictions.bind(this);
    this.api = new API();
  }

  getLabel() {
    if (!!this.state.error) {
      return "Error: " + this.state.error + ". Please try again.";
    }
    switch (this.state.guest.response) {
      case "accept":
        if (!this.state.guest.foodPreference) {
          return "Please select a food preference:";
        }
        return "See you soon!";
      case "decline":
        return "We'll miss you!";
      default:
        return "Let us know if you can make it.";
    }
  }

  getLoadingClass(item) {
    if (this.state.loading === item) {
      return " loading";
    }
    return "";
  }

  renderDietaryRestrictions() {
    if (!this.state.guest.foodPreference) {
      return null;
    }
    return (
      <div className="dietary-restrictions">
        <p className="input-label">Dietary Restrictions</p>
        <DynamicInput
          className="dietary-restrictions-container"
          placeholder="Gluten free? No dairy? Let us know."
          value={this.state.guest.dietaryRestrictions}
          onSave={this.setDietaryRestrictions}
          key={this.state.guest.dietaryRestrictions}
          disabled={this.props.locked}
        />
      </div>
    );
  }

  renderFoodOptions() {
    if (this.state.guest.response === "decline") {
      return null;
    }
    return (
      <div className="food-options">
        <div className="switch vert-switch">
          <div
            onClick={this.setFoodPreference("veg")}
            className="switch-option"
          >
            <span className={"veg " + this.getLoadingClass("veg")} />
            Vegetarian
          </div>
          <div
            onClick={this.setFoodPreference("fish")}
            className="switch-option"
          >
            <span className={"fish " + this.getLoadingClass("fish")} />
            Fish
          </div>
          <div
            onClick={this.setFoodPreference("meat")}
            className="switch-option"
          >
            <span className={"meat " + this.getLoadingClass("meat")} />
            Meat
          </div>
          <div className={"selector " + this.state.guest.foodPreference} />
        </div>
        {this.renderDietaryRestrictions()}
      </div>
    );
  }

  getResponseClass() {
    if (!this.state.guest.response) {
      return "undecided";
    }
    return this.state.guest.response;
  }

  setResponse(response) {
    return function() {
      if (this.props.locked || response === this.state.guest.response) {
        return;
      }
      this.setState({
        ...this.state,
        loading: response
      });
      if (response === "undecided" || response === "decline") {
        if (response === "undecided") {
          response = "";
        }
        this.updateGuest({
          ...this.state.guest,
          response: response,
          foodPreference: null,
          dietaryRestrictions: null
        });
      } else {
        this.updateGuest({
          ...this.state.guest,
          response: response
        });
      }
    }.bind(this);
  }

  setFoodPreference(foodPreference) {
    return function() {
      if (
        this.props.locked ||
        !this.state.guest.response ||
        foodPreference === this.state.guest.foodPreference
      ) {
        return;
      }
      this.setState({
        ...this.state,
        loading: foodPreference
      });
      this.updateGuest({
        ...this.state.guest,
        foodPreference: foodPreference
      });
    }.bind(this);
  }

  setDietaryRestrictions(dietaryRestrictions) {
    if (this.props.locked) {
      return;
    }
    this.updateGuest({
      ...this.state.guest,
      dietaryRestrictions: dietaryRestrictions
    });
  }

  updateGuest(updatedGuest) {
    const inviteCode = localStorage.get("inviteCode");
    if (!inviteCode) {
      throw new Error("no invite code!");
    }

    this.api
      .updateGuest(localStorage.get("inviteCode"), updatedGuest)
      .then(response => response.json())
      .then(data => {
        if (!!data.error) {
          this.setState({
            ...this.state,
            error: data.error,
            loading: ""
          });
          return;
        }
        this.setState({
          guest: updatedGuest,
          loading: "",
          error: ""
        });
        this.props.onUpdate(updatedGuest);
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  getLockedClass() {
    if (this.props.locked) {
      return " locked";
    }
    return "";
  }

  render() {
    return (
      <div
        className={
          "GuestCard " + this.getResponseClass() + this.getLockedClass()
        }
      >
        <p className="guest-name">{this.state.guest.name}</p>
        <div className="switch horiz-switch">
          <div
            onClick={this.setResponse("decline")}
            className={
              "switch-option decline " + this.getLoadingClass("decline")
            }
          />
          <div
            onClick={this.setResponse("undecided")}
            className={
              "switch-option undecided " + this.getLoadingClass("undecided")
            }
          />
          <div
            onClick={this.setResponse("accept")}
            className={"switch-option accept " + this.getLoadingClass("accept")}
          />
          <div className={"selector " + this.getResponseClass()} />
        </div>
        <p className="label">{this.getLabel()}</p>
        {this.renderFoodOptions()}
      </div>
    );
  }
}

export default GuestCard;
