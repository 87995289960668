import React, { Component } from "react";
import "./Gallery.scss";
import { NavLink } from "react-router-dom";

class Gallery extends Component {
  render() {
    return (
      <div className="Gallery">
        <NavLink
          exact
          activeClassName="active"
          to="/guide/lodging"
          className="lodging"
        >
          <span className="guide-title">Lodging</span>
        </NavLink>
        <NavLink activeClassName="active" to="/guide/food" className="food">
          <span className="guide-title">Food</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/guide/wineries"
          className="wineries"
        >
          <span className="guide-title">Wineries</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/guide/activities"
          className="activities"
        >
          <span className="guide-title">Other Activities</span>
        </NavLink>
      </div>
    );
  }
}

export default Gallery;
