import React, { Component } from "react";
import localStorage from "local-storage";
import "./App.scss";
import Splash from "./Splash";
import ReactGA from "react-ga";
import Site from "./Site";

if (window.location.hostname !== "localhost") {
  let options = {};
  if (!!localStorage.get("inviteCode")) {
    options.userId = localStorage.get("inviteCode").toUpperCase();
  }
  ReactGA.initialize("UA-138669673-1", { gaOptions: options });
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { inviteCode: localStorage.get("inviteCode") };
    this.setInviteCode = this.setInviteCode.bind(this);
    this.clearInviteCode = this.clearInviteCode.bind(this);
  }

  setInviteCode(inviteCode) {
    localStorage.set("inviteCode", inviteCode);
    this.setState({ inviteCode: inviteCode });
    if (window.location.hostname !== "localhost") {
      ReactGA.set({ userId: inviteCode });
    }
  }

  clearInviteCode() {
    this.setInviteCode("");
  }

  renderContents() {
    if (!!this.state.inviteCode) {
      return <Site clearInviteCode={this.clearInviteCode} />;
    } else {
      return <Splash setInviteCode={this.setInviteCode} />;
    }
  }

  render() {
    return <div className="App">{this.renderContents()}</div>;
  }
}

export default App;
