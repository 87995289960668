import React, { Component } from "react";
import Measure from "./Measure.js";
import Banner from "./Banner.js";

class Food extends Component {
  constructor(props) {
    super(props);

    this.restaurants = [
      {
        name: "The Restaurant",
        url: "https://www.therestaurantatmeadowood.com/",
        price: "$50 and over",
        distance: 0,
        rating: "4.6 - ‎997 reviews"
      },
      {
        name: "Auberge du Soleil",
        url: "https://aubergeresorts.com/aubergedusoleil/dine/",
        price: "$50 and over",
        distance: 4.3,
        rating: "4.7 - ‎3,332 reviews"
      },
      {
        name: "French Laundry",
        url: "https://www.thomaskeller.com/tfl",
        price: "$50 and over",
        distance: 12.2,
        rating: "4.8 - 760 reviews"
      },
      {
        name: "Singlethread",
        url: "https://singlethreadfarms.com/",
        price: "$50 and over",
        distance: 33,
        rating: "4.7 - Google reviews"
      },
      {
        name: "Solbar",
        url: "https://aubergeresorts.com/solage/dine/",
        price: "$31 to $50",
        distance: 9.8,
        rating: "4.7 - 2499 reviews"
      },
      {
        name: "Valette",
        url: "http://www.valettehealdsburg.com/",
        price: "$31 to $50",
        distance: 35.4,
        rating: "4.8 - 1562 reviews"
      },
      {
        name: "Bouchon Bistro",
        url: "https://www.thomaskeller.com/bouchonyountville",
        price: "$31 to $50",
        distance: 12.4,
        rating: "4.7 - 8496 reviews"
      },
      {
        name: "Santé Restaurant",
        url: "https://www.fairmont.com/sonoma/dining/sante-restaurant/",
        price: "$31 to $50",
        distance: 24.6,
        rating: "4.2 - 1,250 reviews"
      },
      {
        name: "Goose & Gander",
        url: "https://www.goosegander.com/",
        price: "‎$31 to $50",
        distance: 2.2,
        rating: "4.4 - ‎1,583 reviews"
      },
      {
        name: "Ad Hoc",
        url: "https://www.thomaskeller.com/adhoc",
        price: "$31 to $60",
        distance: 12.2,
        rating: "4.7 - 6260 reviews"
      },
      {
        name: "Torc",
        url: "https://www.torcnapa.com/",
        price: "$31 to $50",
        distance: 20.3,
        rating: "4.7 - 1212 reviews"
      },
      {
        name: "Press",
        url: "http://www.pressnapavalley.com/",
        price: "$50 and over",
        distance: 3.3,
        rating: "4.7 - 2146 reviews"
      },
      {
        name: "Farmstead at Long Meadow Ranch",
        url: "https://www.longmeadowranch.com/eat-drink/restaurant",
        price: "$31 to $50",
        distance: 2.2,
        rating: "4.7 - 4391 reviews"
      },
      {
        name: "Mustards Grill",
        url: "http://mustardsgrill.com/",
        price: "$31 to $50",
        distance: 9.9,
        rating: "4.8 - 7128 reviews"
      },
      {
        name: "Oenotri",
        url: "https://oenotri.com/",
        price: "$30 and under",
        distance: 20.4,
        rating: "4.6 - 2246 reviews"
      },
      {
        name: "Brasswood Bar + Kitchen",
        url: "https://brasswood.com/brasswoodbarkitchen",
        price: "$30 and under",
        distance: 3.6,
        rating: "4.6 - 456 reviews"
      },
      {
        name: "Hog Island Oyster Company",
        url: "https://hogislandoysters.com/locations/napa",
        price: "$31 to $50",
        distance: 20.2,
        rating: "4.8 - 397 reviews"
      },
      {
        name: "The Restaurant at CIA Copia",
        url: "https://www.ciaatcopia.com/the-restaurant-at-cia-copia/",
        price: "$30 and under",
        distance: 20.1,
        rating: "4.4 - 901 reviews"
      }
    ];

    this.maxValues = {
      distance: 0
    };

    for (const restaurant of this.restaurants) {
      if (restaurant.distance > this.maxValues.distance) {
        this.maxValues.distance = restaurant.distance;
      }
    }
  }

  render() {
    const restaurants = this.restaurants.map(restaurant => (
      <tr key={restaurant.name}>
        <td>
          <a target="_blank" rel="noopener noreferrer" href={restaurant.url}>
            {restaurant.name}
          </a>
        </td>
        <td>{restaurant.price}</td>
        <td>
          <Measure
            value={restaurant.distance}
            suffix=" miles"
            max={this.maxValues.distance}
          />
        </td>
        <td>{restaurant.rating}</td>
      </tr>
    ));
    return (
      <div className="Food">
        <Banner bannerID="food" title="Food" />
        <div className="content">
          <h3>Restaurants</h3>
          <div className="table-container">
            <table className="table table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th title="Restaurant">Name</th>
                  <th title="Price">Price (according to Google)</th>
                  <th title="Distance">Distance from Venue</th>
                  <th title="Rating">Opentable Rating</th>
                </tr>
              </thead>
              <tbody>{restaurants}</tbody>
            </table>
          </div>
          <h3>Chocolates &amp; Pastries</h3>
          <div className="table-container">
            <table className="table table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th title="Restaurant">Name</th>
                  <th title="Distance">Distance from Venue</th>
                  <th title="Rating">OpenTable Rating</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.thomaskeller.com/yountville-california/bouchon-bakery/bakery"
                    >
                      Bouchon Bakery
                    </a>
                  </td>
                  <td>
                    <Measure value={12.4} suffix=" miles" max={35.4} />
                  </td>
                  <td>4.6 - 949 Google Reviews </td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/ZCRUwcKGHHL2"
                    >
                      Woodhouse Chocolate
                    </a>
                  </td>
                  <td>
                    <Measure value={2.2} suffix=" miles" max={35.4} />
                  </td>
                  <td>4.9 Google reviews </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Food;
