import React, { Component } from "react";
import { Route, NavLink, BrowserRouter as Router } from "react-router-dom";
import { ReactComponent as Seal } from "./images/seal-inverted.svg";
import ScrollToTop from "./ScrollToTop";
import Home from "./Home";
import Story from "./story/Story";
import Details from "./Details";
import Guide from "./guides/Guide";
import RSVP from "./rsvp/RSVP";
import "./Site.scss";

class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
      showButter: false
    };
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleButter = this.toggleButter.bind(this);
    this.collapseNav = this.collapseNav.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  toggleNav() {
    this.setState({
      navExpanded: !this.state.navExpanded
    });
  }

  toggleButter() {
    this.setState({
      showButter: !this.state.showButter
    });
  }

  collapseNav() {
    this.setState({
      navExpanded: false
    });
  }

  getNavClass() {
    if (this.state.navExpanded) {
      return "expanded";
    }
    return "";
  }

  logOut() {
    this.collapseNav();
    this.props.clearInviteCode();
  }

  renderButter() {
    if (!!this.state.showButter) {
      return <div className="buttercup" onClick={this.toggleButter} />;
    }
    return null;
  }

  render() {
    return (
      <div className="Site">
        <div className="container">
          <Router>
            <div className={"nav " + this.getNavClass()}>
              <NavLink
                onClick={this.collapseNav}
                className="ni"
                exact
                activeClassName="active"
                to="/"
              >
                <span>Home</span>
              </NavLink>
              <NavLink
                onClick={this.collapseNav}
                className="ni"
                activeClassName="active"
                to="/story"
              >
                <span>Story</span>
              </NavLink>
              <NavLink
                onClick={this.collapseNav}
                className="ni"
                activeClassName="active"
                to="/details"
              >
                <span>Event Details</span>
              </NavLink>
              <NavLink
                onClick={this.collapseNav}
                className="ni"
                activeClassName="active"
                to="/rsvp"
              >
                <span>RSVP</span>
              </NavLink>
              <div className="ni seal-nav-item">
                <div className="seal">
                  <div className="seal-container">
                    <Seal />
                  </div>
                </div>
              </div>
              <NavLink
                onClick={this.collapseNav}
                className="ni"
                activeClassName="active"
                to="/guide"
              >
                <span>Travel Guide</span>
              </NavLink>
              <a
                onClick={this.collapseNav}
                href="http://www.zola.com/registry/ashlyandryan"
                target="_blank"
                rel="noopener noreferrer"
                className="ni"
              >
                <span>Registry</span>
              </a>
              <button className="link" onClick={this.logOut}>
                Log Out
              </button>
              <div className="spacer" />
              <div className="menu" onClick={this.toggleNav} />
            </div>
            <div className="page">
              <ScrollToTop>
                <Route exact path="/" component={Home} />
                <Route path="/story" component={Story} />
                <Route path="/details" component={Details} />
                <Route path="/guide" component={Guide} />
                <Route path="/rsvp" component={RSVP} />
              </ScrollToTop>
            </div>
          </Router>
        </div>
        <div className="footer">
          Website made with{" "}
          <span className="heart" onClick={this.toggleButter}>
            &#9829;
          </span>{" "}
          by Ryan Atallah and Ashly Huntington
        </div>
        {this.renderButter()}
      </div>
    );
  }
}

export default Site;
