import React, { Component } from "react";
import PhotoWall from "./PhotoWall";
import "./Story.scss";
import { ReactComponent as Divider } from "./images/story-divider.svg";

class Story extends Component {
  constructor(props) {
    super(props);
    this.photoWallPhotos = [
      {
        year: 2014,
        name: "bean-bag",
        title: "Bean Bag Snuggles",
        location: "Atherton, CA"
      },
      {
        year: 2014,
        name: "first-meal",
        title: "First Meal Cooked Together",
        location: "Emerald Hills, CA"
      },
      {
        year: 2015,
        name: "gandalf",
        title: "Halloween",
        location: "Emerald Hills, CA"
      },
      {
        year: 2015,
        name: "sf-hike",
        title: "Prepping for a Hike",
        location: "San Francisco, CA"
      },
      {
        year: 2016,
        name: "camping",
        title: "Camping",
        location: "Mount Lassen, CA"
      },
      {
        year: 2016,
        name: "lassen",
        title: "Hiking Lassen Volcanic Lake",
        location: "Mount Lassen, CA"
      },
      {
        year: 2016,
        name: "symphony",
        title: "Davies Syphony Hall",
        location: "San Francisco, CA"
      },
      {
        year: 2017,
        name: "jedi",
        title: "The Last Jedi",
        location: "Mountain View, CA"
      },
      {
        year: 2017,
        name: "paris",
        title: "Cathédrale Notre-Dame de Paris",
        location: "Paris, France"
      },
      {
        year: 2017,
        name: "pilchuck",
        title: "Hiking to the Top of Mount Pilchuck",
        location: "Mount Pilchuck, WA"
      },
      {
        year: 2017,
        name: "tahoe",
        title: "South Lake Tahoe",
        location: "Lake Tahoe, CA"
      },
      {
        year: 2017,
        name: "racing",
        title: "Racing at Las Vegas Motor Speedway",
        location: "Las Vegas, CA"
      },
      {
        year: 2017,
        name: "vegas",
        title: "Won and Lost in 2 hours",
        location: "Las Vegas, NV"
      },
      {
        year: 2017,
        name: "xmas-party",
        title: "Christmas Party",
        location: "San Francisco, CA"
      },
      {
        year: 2018,
        name: "aspen-hiking",
        title: "Hiking in Aspen",
        location: "Aspen, CO"
      },
      {
        year: 2018,
        name: "aspen",
        title: "Walking in Aspen",
        location: "Aspen, CO"
      },
      {
        year: 2018,
        name: "big-sur",
        title: "Weekend Getaway by the Beach",
        location: "Big Sur, CA"
      },
      {
        year: 2018,
        name: "boating",
        title: "Pedal Boating",
        location: "San Francisco, CA"
      },
      {
        year: 2018,
        name: "cataract-falls",
        title: "Hiking Cataract Falls",
        location: "Stinson Beach, CA"
      },
      {
        year: 2018,
        name: "healdsburg-relaxing",
        title: "4th Year Anniversary",
        location: "Healdsburg, CA"
      },
      {
        year: 2018,
        name: "healdsburg",
        title: "Wine Tasting",
        location: "Healdsburg, CA"
      },
      {
        year: 2018,
        name: "heavenly-ski-break",
        title: "Ski Break at Heavenly",
        location: "Tahoe, CA"
      },
      {
        year: 2018,
        name: "heavenly",
        title: "Ski Time at Heavenly",
        location: "Tahoe, CA"
      },
      {
        year: 2018,
        name: "louisville",
        title: "Home for the Holidays",
        location: "Lousiville, CA"
      },
      {
        year: 2018,
        name: "new-orleans",
        title: "Muriel's Jackson Square",
        location: "New Orleans, LA"
      },
      {
        year: 2018,
        name: "sonoma-heli",
        title: "Helicopter Ride",
        location: "Sonoma, CA"
      },
      {
        year: 2018,
        name: "stawamus",
        title: "Hiking Stawamus Chief",
        location: "Squamish, BC, Canada"
      },
      {
        year: 2018,
        name: "twin-lakes-co",
        title: "Twin Lakes",
        location: "Lake County, CO"
      },
      {
        year: 2018,
        name: "vodka-room",
        title: "The World's Coldest Vodka Tasting Room",
        location: "Whistler, BC, Canada"
      },
      {
        year: 2019,
        name: "pikes-place",
        title: "Chowder Time at Pike's Place",
        location: "Seattle, WA"
      },
      {
        year: 2019,
        name: "salt-lake-snow",
        title: "Snowmobiling",
        location: "Salt Lake City, UT"
      },
      {
        year: 2019,
        name: "sf",
        title: "San Francisco",
        location: "San Francisco, CA"
      }
    ];
  }

  render() {
    return (
      <div className="Story">
        <div className="header">
          <div className="content">
            <h1>Story</h1>
            <Divider />
            <p>
              It started with, <strong>“I have a problem.”</strong> Well, not
              really...
            </p>
            <p>
              On the night of August 30th 2013, Ashly arrived at a house in her
              pink pajamas, thinking that only her friend was inside. Oh how she
              was wrong. There were several people, some familiar, and others,
              not so much. In the bunch of not-so-much was this sweaty
              glasses-wearing guy who had just cycled home from
              Facebook&mdash;Ryan.
            </p>
            <p>
              Though Ashly wasn’t in a particularly chatty mood, Ryan made sure
              she reconsidered. They spoke for hours into the early morning and
              both realized that a strong friendship was inevitable. Over the
              course of the next year Ashly maintained her no-dating policy but
              had attempted to set Ryan up on a few romantic adventures. During
              one of their get-togethers, Ryan was complaining about dating and
              a very strange thought passed over Ashly, “Who wouldn’t want to
              date this guy? I mean, he’s perfect.” This was... <i>not</i> a
              comfortable moment. Ashly liked him... so she decided to make this
              sentiment known with her version of obvious... Ryan had no clue.
              (Or at least, that’s how he acted... we’ll never know.)
            </p>
            <p>
              Months of awkward ‘flirting’ followed. Finally, Ashly did what all
              good flirters do: she announced plainly to Ryan,{" "}
              <strong>“I have a problem... I like you.”</strong> Ryan simply
              smiled and said, <strong>“I like you, too.”</strong> From that
              point on, they’ve been inseparable adventure-buddies, partners in
              crime, and best friends.
            </p>
          </div>
          <div className="overlay-shadow bottom" />
        </div>
        <PhotoWall photos={this.photoWallPhotos} />
      </div>
    );
  }
}

export default Story;
