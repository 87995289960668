import React, { Component } from "react";
import "./Details.scss";

class Details extends Component {
  render() {
    return (
      <div className="Details">
        <div className="header">
          <h1>Event Details</h1>
          <div className="overlay-shadow top" />
        </div>
        <div className="content event-logistics">
          <div className="section overview">
            <p className="venue-name">Meadowood Napa Valley</p>
            <p className="address">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/88zJEiSBdD22"
              >
                900 Meadowood Ln
                <br />
                St Helena, CA 94574
              </a>
            </p>
            <p className="date">October 18th, 2019</p>
          </div>
          <div className="section schedule">
            <table>
              <thead>
                <tr>
                  <th title="What">What</th>
                  <th title="When">When</th>
                  <th title="Where">Where</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Guest Arrival</td>
                  <td>4:30pm</td>
                  <td>Croquet Glen</td>
                </tr>
                <tr>
                  <td>Wedding Ceremony</td>
                  <td>5:00pm</td>
                  <td>Croquet Glen</td>
                </tr>
                <tr>
                  <td>Cocktails</td>
                  <td>5:30pm</td>
                  <td>Vitners Glen</td>
                </tr>
                <tr>
                  <td>Coursed Dinner</td>
                  <td>6:30pm</td>
                  <td>Vitners Glen</td>
                </tr>
                <tr>
                  <td>Reception</td>
                  <td>8:30pm</td>
                  <td>Woodside Room & Glen</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="map" />
        <div className="content dress-code">
          <h2>Black Tie Optional</h2>
          <p>It will be around 50°F in the evening; you may want a coat!</p>
          <div className="sections">
            <div className="section">
              <div className="icon dress" />
              Floor-length dress, pantsuit, or cocktail dress. Please keep loud
              colors to a minimum, no white, and cuts on the conservative side.
            </div>
            <div className="section">
              <div className="icon tuxedo" />
              Tuxedo or dark business suit with a plain white shirt, and either
              necktie or bowtie. Solid and muted colors preferred.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
