import React, { Component } from "react";
import "./Measure.scss";

class Measure extends Component {
  constructor(props) {
    super(props);
    this.getWidth = this.getWidth.bind(this);
  }

  getWidth() {
    return (this.props.value / this.props.max) * 100 + "%";
  }

  render() {
    return (
      <div className="Measure">
        <span className="label">
          {this.props.prefix}
          {this.props.value}
          {this.props.suffix}
        </span>
        <div className="bar" style={{ width: this.getWidth() }} />
      </div>
    );
  }
}

export default Measure;
