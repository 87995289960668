import React, { Component } from "react";
import "./Guide.scss";
import { Route, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Gallery from "./Gallery";
import Food from "./Food";
import Lodging from "./Lodging";
import Wineries from "./Wineries";
import OtherActivities from "./OtherActivities";

class Guide extends Component {
  render() {
    return (
      <div className="Guide">
        <Router>
          <div className="guide-page">
            <ScrollToTop>
              <Route exact path="/guide" component={Gallery} />
              <Route path="/guide/lodging" component={Lodging} />
              <Route path="/guide/food" component={Food} />
              <Route path="/guide/wineries" component={Wineries} />
              <Route path="/guide/activities" component={OtherActivities} />
            </ScrollToTop>
          </div>
        </Router>
      </div>
    );
  }
}

export default Guide;
