import React, { Component } from "react";
import Banner from "./Banner.js";

class Wineries extends Component {
  render() {
    return (
      <div className="Wineries">
        <Banner bannerID="wineries" title="Wineries" />
        <div className="content">
          <div className="table-container">
            <table className="table table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th title="Vineyards">Vineyards</th>
                  <th title="Varietals">Varietals</th>
                  <th title="Food Pairing">Food Pairing</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.deldottovineyards.com/"
                    >
                      Del Dotto Vineyards
                    </a>
                  </td>
                  <td>
                    Chardonnay, Sauvignon Blanc, Pinot Noir, Syrah, Cabernet
                    Sauvignon, Cabernet Franc, Merlot, Sangiovese
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.darioush.com/our-wine"
                    >
                      Darioush Winery
                    </a>
                  </td>
                  <td>
                    Cabernet Sauvignon, Shiraz, Merlot, Pinot Noir, Cabernet
                    Franc, Chardonnay, Sauvignon Blanc, Viognier
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://castellodiamorosa.com/"
                    >
                      Castello Di Amorosa
                    </a>
                  </td>
                  <td>
                    Barbera, Cabernet Sauvignon, Chardonnay, Gewurztraminer,
                    Late Harvest/Dessert, Merlot, Muscat/Moscato, Pinot Blanc,
                    Pinot Grigio/Pinot Gris, Pinot Noir, Red Blend, Rose,
                    Sangiovese, Sparkling Wine, Zinfandel
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.emeritusvineyards.com/"
                    >
                      Emeritus Vineyards
                    </a>
                  </td>
                  <td>Pinot Noir</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.robertmondaviwinery.com/"
                    >
                      Robert Mondavi Winery
                    </a>
                  </td>
                  <td>
                    Cabernet Sauvignon, Merlot, Cabernet Franc, Malbec, Petit
                    Verdot 
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.diamondcreekvineyards.com/gravelly.html"
                    >
                      Diamond Creek Gravelly Meadow Napa Valley
                    </a>
                  </td>
                  <td>Cabernet Sauvignon</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.hallwines.com/"
                    >
                      Hall Saint Helena
                    </a>
                  </td>
                  <td>Cabernet Sauvignon, Sauvignon Blanc, Syrah, Merlot</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ledson.com/"
                    >
                      Ledson Winery &amp; Vineyards
                    </a>
                  </td>
                  <td>
                    Sauvignon Blanc, Viognier, Chardonnay, Pinot Noir,
                    Zinfandel, Petite Sirah, Syrah, Merlot
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.kj.com/wine"
                    >
                      Kendall-Jackson Wine Estate &amp; Gardens
                    </a>
                  </td>
                  <td>
                    Sauvignon Blanc, Chardonnay, Pinot Gris, Rosé, Riesling
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://harlanestate.com/"
                    >
                      Harlan Estate 
                    </a>
                  </td>
                  <td>
                    Cabernet Sauvignon, Merlot, Cabernet Franc, Petit Verdot
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.josephphelps.com/"
                    >
                      Joseph Phelps
                    </a>
                  </td>
                  <td>
                    Syrah, Riesling, Pinot Noir, Cabernet Sauvignon, Chardonnay,
                    Red Blend
                  </td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Wineries;
