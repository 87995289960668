import React, { Component } from "react";
import { ReactComponent as Seal } from "./images/seal-inverted.svg";
import API from "./API";
import ReactGA from "react-ga";
import "./Splash.scss";

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      error: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.api = new API();
  }

  componentDidMount() {
    if (window.location.hostname !== "localhost") {
      ReactGA.set({ page: "/splash" });
      ReactGA.pageview("/splash");
    }
  }

  handleChange(event) {
    this.setState({
      code: event.target.value,
      error: ""
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.api
      .signIn(this.state.code)
      .then(response => response.json())
      .then(data => {
        if (!!data.error) {
          this.setState({ error: data.error });
        } else {
          this.props.setInviteCode(this.state.code.toUpperCase());
        }
      })
      .catch(error => {
        this.setState({ error: "Internal Server Error" });
        console.log("error", error);
      });
  }

  renderErrorMessage() {
    if (!!this.state.error) {
      return <p className="error">{this.state.error}</p>;
    }
    return null;
  }

  render() {
    return (
      <div className="Splash">
        <div className="container">
          <div className="seal">
            <Seal />
          </div>
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              className="invite-code-input"
              value={this.state.code}
              name="code"
              onChange={this.handleChange}
              placeholder="Invite Code"
            />
            <input className="submit-button" type="submit" value="&rarr;" />
            {this.renderErrorMessage()}
          </form>
        </div>
      </div>
    );
  }
}

export default Splash;
