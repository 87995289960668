class API {
  constructor(props) {
    if (window.location.hostname === "localhost") {
      this.endpoint = "http://localhost:4321/HandleRequest";
    } else {
      this.endpoint =
        "https://us-central1-thistle.cloudfunctions.net/HandleRequest";
    }
  }

  signIn(inviteCode) {
    var myHeaders = new Headers({
      "Invite-Code": inviteCode,
      "Request-Name": "SignIn"
    });
    return fetch(this.endpoint, {
      method: "GET",
      mode: "cors",
      headers: myHeaders
    });
  }

  getGuests(inviteCode) {
    var myHeaders = new Headers({
      "Invite-Code": inviteCode,
      "Request-Name": "GetGuests"
    });
    return fetch(this.endpoint, {
      method: "GET",
      mode: "cors",
      headers: myHeaders
    });
  }

  updateGuest(inviteCode, data) {
    var myHeaders = new Headers({
      "Invite-Code": inviteCode,
      "Request-Name": "UpdateGuest"
    });
    const body = { guest: data };
    return fetch(this.endpoint, {
      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify(body)
    });
  }
}

export default API;
