import React, { Component } from "react";
import localStorage from "local-storage";
import GuestCard from "./GuestCard";
import API from "../API";
import "./RSVP.scss";

class RSVP extends Component {
  constructor(params) {
    super(params);
    this.state = {
      guests: [],
      isLoading: true
    };
    this.updateGuest = this.updateGuest.bind(this);
    this.api = new API();
  }

  componentDidMount() {
    const inviteCode = localStorage.get("inviteCode");
    if (!inviteCode) {
      throw new Error("no invite code!");
    }

    this.api
      .getGuests(localStorage.get("inviteCode"))
      .then(response => response.json())
      .then(data => {
        if (!!data.error) {
          throw new Error("failed to get guests: " + data.error);
        }
        this.setState({
          isLoading: false,
          guests: data.data
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  renderGuests() {
    return this.state.guests.map(guest => (
      <GuestCard
        guest={guest}
        onUpdate={this.updateGuest}
        key={guest.name}
        locked={this.locked()}
      />
    ));
  }

  locked() {
    var deadline = new Date(this.state.guests[0].deadline);
    return new Date() > deadline;
  }

  updateGuest(guest) {
    for (let i = 0; i < this.state.guests.length; i++) {
      if (guest.name === this.state.guests[i].name) {
        this.updateGuestAtIndex(guest, i);
        return;
      }
    }
    throw new Error("unrecognized guest could not be updated: " + guest.name);
  }

  updateGuestAtIndex(updatedGuest, index) {
    let guests = [...this.state.guests];
    guests[index] = updatedGuest;
    this.setState({ guests: guests });
  }

  renderHeadingText() {
    if (this.state.isLoading) {
      return <p>Loading...</p>;
    }

    var deadline = "August 18, 2019";
    if (!!this.state.guests[0].deadline) {
      if (this.locked()) {
        return (
          <p>
            <strong>Responses locked.</strong> Please reach out if you need to
            make any further changes!
          </p>
        );
      }
      deadline = new Date(this.state.guests[0].deadline).toLocaleDateString(
        "en-US",
        { month: "long", day: "numeric", year: "numeric" }
      );
    }

    let done = true;
    for (let i = 0; i < this.state.guests.length; i++) {
      let g = this.state.guests[i];
      if (!g.response || (g.response !== "decline" && !g.foodPreference)) {
        done = false;
      }
    }

    if (done) {
      return (
        <p>
          <strong>You're all set!</strong>
        </p>
      );
    }
    return (
      <div>
        <p>
          <strong>RSVP incomplete &mdash;</strong> Please finish by {deadline}!
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="RSVP">
        <div className="header">
          <div className="rsvp-status">{this.renderHeadingText()}</div>
          <div className="overlay-shadow bottom" />
        </div>
        <div className="guest-container">{this.renderGuests()}</div>
      </div>
    );
  }
}

export default RSVP;
