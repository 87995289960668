import { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { PropTypes } from "prop-types";

class ScrollToTop extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.props.history);
    this.props.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    let pathname = location.pathname || "/";
    if (window.location.hostname !== "localhost") {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
